<template>
    <div v-if="validationError">
        <p class="text-center text-md-start mt-2">
            <component v-if="userMessageComponent" v-bind:is="userMessageComponent"></component>
            <span v-else>{{ userMessage }}</span>
        </p>
        <div class="text-center text-md-start mt-2">
            <div class="position-relative" style="z-index: 1">
                <div>
                    <button class="btn btn--large btn--full-width-mobile btn--min-width-250 btn-tertiary mt-3"
                        @click="() => authAndCollect()">Försök igen</button>
                </div>
                <div>
                    <button @click="cancelAndRedirect"
                        class="btn btn--large btn--full-width-mobile btn--min-width-250 btn-secondary mt-2">Avbryt</button>
                </div>
            </div>
            <img class="d-block d-sm-none bottom-illustration--fixed"
                src="/dist/assets/images/illustration-confused-small.svg" width="375" height="296"
                alt="Illustration - Något gick fel" />
            <img class="d-none d-sm-inline-block mt-3" src="/dist/assets/images/illustration-confused.svg" width="512"
                height="340" alt="Illustration - Något gick fel" />
        </div>
    </div>
    <div v-else>
        <div v-if="!validationComplete">
            <div v-if="!validationUserSignPending">
                <div class="text-center text-md-start mt-1">
                    <div class="loader-spinner mt-3 mb-2" v-if="!qrCodeData">
                        <span>Laddar ...</span>
                    </div>
                    <v-qr-code :text="qrCodeData" v-if="qrCodeData" :key="qrCodeData"></v-qr-code>
                </div>
                <ol class="mt-1 text-start d-inline-block">
                    <li>Starta BankID-appen på din mobil eller surfplatta</li>
                    <li>Tryck på QR-symbolen längst ner i BankID-appen</li>
                    <li>Rikta kameran mot den här QR-koden</li>
                </ol>
            </div>
            <div v-else class="text-center text-md-start">
                <div class="loader-spinner mt-3">
                    <span>Laddar ...</span>
                </div>
                <p class="text-start mt-1">
                    <component v-if="userMessageComponent" v-bind:is="userMessageComponent"></component>
                    <span v-else>{{ userMessage }}</span>
                </p>

            </div>
            <div class="text-center text-md-start">
                <button class="btn btn--large btn--full-width-mobile btn-tertiary mt-3" @click="cancelAndRedirect">Avbryt</button>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, watch} from "vue";
import { getBankIdLogic } from './bank-id-logic';
import VSegmenteringForm from './v-segmentering-form.vue'
import { useSharedStore } from "@/Pages/Shared/Utility/shared-store";

export default {
    components: { VSegmenteringForm },

    setup(_, context) {
        const store = useSharedStore();
        const {
            qrCodeData,
            userMessage,
            userMessageComponent,
            validationComplete,
            validationUserSignPending,
            validationError,
            authAndCollect,
            cancel
        } = getBankIdLogic('/api/bank-id');

        watch(validationComplete, (newValue) => {
            if (newValue) {
                if(store.onComplete === ''){
                    context.emit('bankIdComplete', true)
                    store.clearMode()
                }
            }
        });

        onMounted(async () => {
            await authAndCollect();
        })

        const cancelAndRedirect = async () => {
            await cancel();
            store.clearMode();
            location.href = `${window.location}`;
        }

        return {
            qrCodeData,
            userMessage,
            userMessageComponent,
            validationComplete,
            validationUserSignPending,
            validationError,
            authAndCollect,
            cancelAndRedirect,
        }
    }
}
</script>