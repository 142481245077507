<template>
    <div v-if="store.mode === 'init'">
        <p>Du kan välja att öppna BankID från annan enhet eller på den enhet du befinner dig på.</p>
        <div class="width--max320 m-auto mt-4">
            <div class="row">

                <div class="order-lg-2 col-12">
                    <button data-ga-event-action="Klick" data-ga-event-label="Validera BankID på samma enhet" data-ga-event-page="Validera ditt konto"
                       class="d-flex d-lg-none btn btn--large btn--full-width btn-bankid mt-0 mt-lg-3" @click="selectDeviceMode">
                        BankID på
                        samma enhet
                    </button>
                    <button data-ga-event-action="Klick" data-ga-event-label="Validera BankID på samma enhet" data-ga-event-page="Validera ditt konto"
                       class="d-none d-lg-flex btn btn--large btn--full-width btn-bankid btn-bankid--outline mt-0 mt-lg-3" @click="selectDeviceMode">
                        BankID på
                        samma enhet
                    </button>
                </div>
                <div class="order-lg-1 col-12 mt-3 mt-lg-0">
                    <button data-ga-event-action="Klick" data-ga-event-label="Validera BankID på annan enhet" data-ga-event-page="Validera ditt konto" class="d-flex d-lg-none btn btn--large btn--full-width btn-bankid  btn-bankid--outline" @click="selectAnotherDeviceMode">
                        BankID på annan
                        enhet
                    </button>
                    <button data-ga-event-action="Klick" data-ga-event-label="Validera BankID på annan enhet" data-ga-event-page="Validera ditt konto" class="d-none d-lg-flex btn btn--large btn--full-width btn-bankid" @click="selectAnotherDeviceMode">
                        BankID på annan
                        enhet
                    </button>
                </div>

            </div>
        </div>
    </div>
    <v-bank-id-validation-qr v-else-if="store.mode === 'another-device'" v-on:bankIdComplete="bankIdComplete"></v-bank-id-validation-qr>
    <v-bank-id-device v-else-if="store.mode === 'device'" v-on:bankIdComplete="bankIdComplete"></v-bank-id-device>
</template>

<script lang="ts">
import VBankIdValidationQr from "./v-bank-id-validation-qr";
import VBankIdDevice from "./v-bank-id-validation-device";
import { useSharedStore} from "@/Pages/Shared/Utility/shared-store";
import { useSessionStorageRef } from "@/Pages/Shared/Utility/sessionStorage";
import {defineComponent} from "vue";

type BankIdMode = 'init' | 'device' | 'another-device' | 'complete';

export default defineComponent({
    components: {VBankIdDevice, VBankIdValidationQr},

    props: {

        // cancelRedirect: {
        //     type: String,
        //     require: true
        // },
        action: {
            type: String,
            require: true
            },
        actionInput:  {
            type: String,
            required: false
        },
        onComplete: {
            type: String,
            required: true
        }
    },


    setup(props, context) {

        const store = useSharedStore();

        if(props.action != undefined){
            store.setAction(props.action)
        }
        if(props.actionInput != undefined){
            store.setActionInput(props.actionInput)
        }
        if(props.onComplete != undefined){
            store.setOnComplete(props.onComplete)
        }


        const bankIdComplete = async (val: boolean) => {
            if(val){
                store.setMode('complete')
                context.emit('bankIdComplete', val)
            }
        }

        const selectDeviceMode = () => {
            store.setMode('device');
        }

        const selectAnotherDeviceMode = () => {
            store.setMode('another-device')
        }

        return {
            store,
            selectDeviceMode,
            selectAnotherDeviceMode,
            bankIdComplete
        }
    }
})
</script>