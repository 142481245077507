import axios from 'axios';
import { ref } from 'vue';
import { setIntervalAsync, clearIntervalAsync } from 'set-interval-async';
import { logErrorToSentry } from "@/src/js/sentry";
import { useSharedStore} from "@/Pages/Shared/Utility/shared-store";

export const getBankIdLogic = (path) => {
    let failCount = 0;
    let pollIntervalId = null;
    let orderRef = null;

    const autoStartToken = ref('');
    const qrCodeData = ref('');
    const userMessage = ref('');
    const userMessageComponent = ref(null);
    const validationCanceled = ref(false);
    const validationComplete = ref(false);
    const validationError = ref(false);
    const validationUserSignPending = ref(false);
    const validationResume = ref(false);

    const collect = async () => {
        try {
            const store = useSharedStore();

            // Guard for pending collects
            if (validationComplete.value || validationError.value) {
                return;
            }

            const res = await axios.get(`${path}/collect/${orderRef}`);


            //se över vilka meddelanden som kommer vara aktuella nu där inte bankidcontroller hanterar logiken som
            //tidigare skicka tillbaka dem utan det görs i verificationcontroller nu istället
            switch (res.data.status) {
                case 'PENDING_OUTSTANDING_TRANSACTION':
                    qrCodeData.value = res.data.qrCode;
                    break;
                case 'PENDING_USER_SIGN':
                    validationUserSignPending.value = true;
                    userMessage.value = 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera.';
                    break;
                case 'PENDING_UNKNOWN':
                    validationUserSignPending.value = true;
                    userMessage.value = 'Identifiering eller underskrift pågår.';
                    break;
                case 'PENDING_NO_CLIENT':
                    userMessage.value = 'Starta BankID-appen.';
                    break;
                case 'PENDING_STARTED':
                    userMessage.value = 'Söker efter BankID, det kan ta en liten stund.';
                    break;
                case 'COMPLETE':
                    clearIntervalAsync(pollIntervalId);
                    validationUserSignPending.value = false;
                    sessionStorage.removeItem('_orderRef');
                    await bankIdCallback(res.data.completionData, store.actionInput, store.action, store.onComplete)
                    validationComplete.value = true;
                    break;
                case 'FAILED_START_FAILED':
                    if (failCount < 3) {
                        clearIntervalAsync(pollIntervalId);
                        await authAndCollect();
                        failCount += 1;
                        return;
                    }
                    defaultErrorHandler({ message: 'BankID-appen verkar inte finnas i din dator eller telefon. Installera den och hämta ett BankID hos din internetbank.' });
                    break;
                case 'FAILED_USER_CANCEL':
                    defaultErrorHandler({ message: 'Åtgärden avbruten.' });
                    break;
                case 'FAILED_CERTIFICATE_ERR':
                    defaultErrorHandler({ message: 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.' });
                    break;
                case 'FAILED_EXPIRED_TRANSACTION':
                    defaultErrorHandler({ message: 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din bank. Försök sedan igen' });
                    break;
                case 'FAILED_CANCELLED':
                    defaultErrorHandler({ message: 'Åtgärden avbruten. Försök igen.' });
                    break;
                case 'ERROR_ALREADY_IN_PROGRESS':
                    defaultErrorHandler({ message: 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.' });
                    break;
                case 'FAILED_UNKNOWN':
                case 'ERROR_UNDERAGE':
                    defaultErrorHandler({ message: 'Det går inte att validera medlemskapet, du behöver vara över 16 år för att skapa ett konto och validera dig hos oss.' });
                    break;
                case 'ALREADY_COMPLETE':
                    defaultErrorHandler({ message: 'Giltighetstiden på identifieringen har löpt ut. Försök igen.'});
                    break;
                case 'ERROR_UNKNOWN':
                case 'ERROR_INVALID_PARAMETERS':
                case 'ERROR_NOT_FOUND':
                case 'ERROR_METHOD_NOT_ALLOWED':
                case 'ERROR_UNSUPPORTED_MEDIA_TYPE':
                    defaultErrorHandler({ message: 'Okänt fel. Försök igen.' });
                    break;
                case 'ERROR_INTERNAL_ERROR':
                case 'ERROR_REQUEST_TIMEOUT':
                case 'ERROR_MAINTENANCE':
                    defaultErrorHandler({ message: 'Internt tekniskt fel. Försök igen.' });
                    break;
            }
        } catch (error) {
            logErrorToSentry(error);
            defaultErrorHandler({ message: 'Okänt fel. Försök igen.' });
        }
    }

    const authAndCollectWithResume = async (forceNewOrder = false) => {
        const orderRefToResume = sessionStorage.getItem('_orderRef');

        if (!forceNewOrder && orderRefToResume) {
            await resumeOrder(orderRefToResume);
        } else {
            await authAndCollect();
        }
    }

    const authAndCollect = async () => {
        validationResume.value = false;
        validationCanceled.value = false;
        validationComplete.value = false;
        validationUserSignPending.value = false;
        validationError.value = false;
        userMessage.value = '';
        userMessageComponent.value = null;
        sessionStorage.removeItem('_orderRef');

        try {
            const res = await axios.get(`${path}/auth`);
            orderRef = res.data.orderRef;
            qrCodeData.value = res.data.qrCode;
            autoStartToken.value = res.data.autoStartToken;
            sessionStorage.setItem('_orderRef', orderRef);
            pollIntervalId = setIntervalAsync(collect, 2000);
        }
        catch (error) {
            logErrorToSentry(error);
            validationError.value = true;
            userMessage.value = "Internt tekniskt fel. Försök igen.";
        }
    }

    const resumeOrder = async (orderRefToResume) => {
        orderRef = orderRefToResume;
        validationResume.value = true;
        userMessage.value = '';
        userMessageComponent.value = null;

        pollIntervalId = setIntervalAsync(collect, 2000);
    }

    const cancel = async () => {
        try {
            await clearIntervalAsync(pollIntervalId);
            await axios.get(`${path}/cancel/${orderRef}`);
        }
        catch (error) {
            logErrorToSentry(error);
        }

        validationComplete.value = false;
        validationError.value = false;
        validationResume.value = false;
        validationUserSignPending.value = false;
        userMessage.value = null;
        userMessageComponent.value = null;
        validationCanceled.value = true;
        sessionStorage.removeItem('_orderRef');
    }

    const defaultErrorHandler = ({ message, component }) => {
        clearIntervalAsync(pollIntervalId);
        validationError.value = true;
        validationComplete.value = false;
        validationUserSignPending.value = false;
        if (component) {
            userMessageComponent.value = component;
        } else {
            userMessage.value = message ?? '';
        }
    }

    const bankIdCallback = async (completionData, inputData, action, onComplete) => {
        try {
            const callbackUrl = new URL(window.location.origin);
            callbackUrl.pathname = action;
            await axios.post(callbackUrl.toString() ,{
                InputData: inputData,
                CompletionData: completionData
            });
            if(onComplete !== ''){
                sessionStorage.clear()
                location.href = `${window.location.origin}/${onComplete}`
            }
        } catch (err) {
            logErrorToSentry(err);
            console.error('There was an error:' + err);
        }
    }

    return {
        qrCodeData,
        autoStartToken,
        userMessage,
        userMessageComponent,
        validationResume,
        validationComplete,
        validationUserSignPending,
        validationError,
        validationCanceled,
        authAndCollect,
        authAndCollectWithResume,
        cancel
    }
}