import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "width--max320 m-auto mt-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "order-lg-2 col-12" }
const _hoisted_5 = { class: "order-lg-1 col-12 mt-3 mt-lg-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_bank_id_validation_qr = _resolveComponent("v-bank-id-validation-qr")!
  const _component_v_bank_id_device = _resolveComponent("v-bank-id-device")!

  return (_ctx.store.mode === 'init')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[7] || (_cache[7] = _createElementVNode("p", null, "Du kan välja att öppna BankID från annan enhet eller på den enhet du befinner dig på.", -1 /* HOISTED */)),
        _cache[8] || (_cache[8] = _createTextVNode()),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                "data-ga-event-action": "Klick",
                "data-ga-event-label": "Validera BankID på samma enhet",
                "data-ga-event-page": "Validera ditt konto",
                class: "d-flex d-lg-none btn btn--large btn--full-width btn-bankid mt-0 mt-lg-3",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectDeviceMode && _ctx.selectDeviceMode(...args)))
              }, "\n                        BankID på\n                        samma enhet\n                    "),
              _cache[4] || (_cache[4] = _createTextVNode()),
              _createElementVNode("button", {
                "data-ga-event-action": "Klick",
                "data-ga-event-label": "Validera BankID på samma enhet",
                "data-ga-event-page": "Validera ditt konto",
                class: "d-none d-lg-flex btn btn--large btn--full-width btn-bankid btn-bankid--outline mt-0 mt-lg-3",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectDeviceMode && _ctx.selectDeviceMode(...args)))
              }, "\n                        BankID på\n                        samma enhet\n                    ")
            ]),
            _cache[6] || (_cache[6] = _createTextVNode()),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                "data-ga-event-action": "Klick",
                "data-ga-event-label": "Validera BankID på annan enhet",
                "data-ga-event-page": "Validera ditt konto",
                class: "d-flex d-lg-none btn btn--large btn--full-width btn-bankid btn-bankid--outline",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.selectAnotherDeviceMode && _ctx.selectAnotherDeviceMode(...args)))
              }, "\n                        BankID på annan\n                        enhet\n                    "),
              _cache[5] || (_cache[5] = _createTextVNode()),
              _createElementVNode("button", {
                "data-ga-event-action": "Klick",
                "data-ga-event-label": "Validera BankID på annan enhet",
                "data-ga-event-page": "Validera ditt konto",
                class: "d-none d-lg-flex btn btn--large btn--full-width btn-bankid",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectAnotherDeviceMode && _ctx.selectAnotherDeviceMode(...args)))
              }, "\n                        BankID på annan\n                        enhet\n                    ")
            ])
          ])
        ])
      ]))
    : (_ctx.store.mode === 'another-device')
      ? (_openBlock(), _createBlock(_component_v_bank_id_validation_qr, {
          key: 1,
          onBankIdComplete: _ctx.bankIdComplete
        }, null, 8 /* PROPS */, ["onBankIdComplete"]))
      : (_ctx.store.mode === 'device')
        ? (_openBlock(), _createBlock(_component_v_bank_id_device, {
            key: 2,
            onBankIdComplete: _ctx.bankIdComplete
          }, null, 8 /* PROPS */, ["onBankIdComplete"]))
        : _createCommentVNode("v-if", true)
}